@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    @apply font-nunito scroll-smooth;
}


#ResumeButton {
    @apply bg-transparent hover:bg-white/[0.5];
}

#ProjectButton {
    @apply bg-white/[0.0] hover:bg-white/[0.5];
}

#HireMeButton {
    @apply bg-teal-500/[0.2] hover:bg-teal-500;
}

.temporary-bounce {
    animation-iteration-count: 5;
}

.navbar-Button {
    @apply hover:backdrop-blur-sm rounded-lg px-7 py-3 pl-3 text-xl font-nunito font-semibold place-items-center;
}

.navbar-brand {
    @apply font-bold font-serif text-3xl mx-5 lg:text-4xl text-center bg-gradient-to-r from-rose-500 to-purple-400 bg-clip-text drop-shadow-lg text-transparent;
}

.navbar-menuButton {
    @apply lg:hidden rounded-full border-transparent mx-5 bg-gradient-to-r from-rose-500 to-purple-400 bg-clip-content
    duration-300
}

.socialItemLinkElement {
    @apply justify-between
    text-xl lg:text-2xl
    duration-300
    space-x-2
    flex

}

.introHeading {
    @apply text-2xl lg:text-3xl font-mono font-bold ease-in-out duration-1000
    w-full grid grid-cols-2 grid-rows-none justify-start sm:text-center px-5 sm:px-0
}

.introHeading-Button {
    @apply mx-0.5 md:mx-5 sm:px-7 sm:py-3 lg:mx-10 px-5 py-2 border-2 rounded-lg ease-in-out duration-300
    hover:text-white hover:text-lg lg:text-lg text-sm hidden sm:block
}

.MyWorkButton {
    @apply flex flex-row place-items-center space-x-2 rounded-lg hover:text-white
    bg-gradient-to-l from-rose-500 to-purple-400 bg-clip-text text-transparent hover:bg-clip-border duration-300 ease-in-out

}


.about-me {
    @apply text-center flex flex-col absolute bottom-0 inset-x-0 text-xl items-center;
}

.typewrite {
    @apply border-r-4 border-r-green-700 text-center
}

.hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
    display: none;
}
